import React, { useEffect, useRef } from 'react'
import Form from 'react-bootstrap/Form'
import { useStateSwitcher } from '../SwitcherContext'

function SwitchTheme() {
	// const [isChecked, setIsChecked] = useState(false)
	const { isChecked, handleSwitchChange } = useStateSwitcher()

	// const handleSwitchChange = () => {
	// 	setIsChecked(!isChecked)
	// }
	//console.log('isChecked', isChecked)
	const stickyRef = useRef(null)
	const containerRef = useRef(null)

	useEffect(() => {
		const handleScroll = () => {
			const container = containerRef.current
			const sticky = stickyRef.current

			if (!container || !sticky) return

			const containerRect = container.getBoundingClientRect()
			const stickyRect = sticky.getBoundingClientRect()
			const maxWidth = window.matchMedia('(max-width: 1200px)').matches

			if (maxWidth) {
				// if (containerRect.top < 0) {
				// 	//sticky.style.position = 'fixed'
				// 	sticky.style.top = '0'
				// } else {
				sticky.style.position = 'sticky'
				sticky.style.top = '0'
				// }
			} else {
				sticky.style.position = 'relative'
				sticky.style.top = 'auto'
			}
		}

		document.addEventListener('scroll', handleScroll)
		window.addEventListener('resize', handleScroll)

		// Initial check
		handleScroll()

		return () => {
			document.removeEventListener('scroll', handleScroll)
			window.removeEventListener('resize', handleScroll)
		}
	}, [])
	return (
		<>
			<Form ref={stickyRef} className="switch-form sticky-block">
				<Form.Check
					className={
						isChecked
							? 'switch-checked-btn switch-btn d-flex flex-column '
							: 'switch-btn  d-flex flex-column '
					}
					type="switch"
					id="custom-switch"
					checked={isChecked}
					onChange={handleSwitchChange}
					label={isChecked ? 'Indoor' : 'Outdoor'}
				/>
			</Form>
		</>
	)
}

export default SwitchTheme
