export const DoorMapping = [
	{
		id: 'fb1',
		category: 'fiberglass',
		src: '/fiberglass/1.jpeg',
	},
	{
		id: 'fb2',
		category: 'fiberglass',
		src: '/fiberglass/2.jpeg',
	},
	{
		id: 'fb3',
		category: 'fiberglass',
		src: '/fiberglass/3.jpeg',
	},
	{
		id: 'fb4',
		category: 'fiberglass',
		src: '/fiberglass/4.jpeg',
	},
	{
		id: 'fb5',
		category: 'fiberglass',
		src: '/fiberglass/5.jpeg',
	},
	{
		id: 'fb6',
		category: 'fiberglass',
		src: '/fiberglass/6.jpeg',
	},
	{
		id: 'fb7',
		category: 'fiberglass',
		src: '/fiberglass/7.jpeg',
	},
	{
		id: 'fb8',
		category: 'fiberglass',
		src: '/fiberglass/8.jpeg',
	},
	{
		id: 'fb9',
		category: 'fiberglass',
		src: '/fiberglass/9.jpeg',
	},
	{
		id: 'fb10',
		category: 'fiberglass',
		src: '/fiberglass/10.jpeg',
	},
	{
		id: 'fb11',
		category: 'fiberglass',
		src: '/fiberglass/11.jpeg',
	},
	{
		id: 'fb12',
		category: 'fiberglass',
		src: '/fiberglass/12.jpeg',
	},
	{
		id: 'fb13',
		category: 'fiberglass',
		src: '/fiberglass/13.jpeg',
	},
	{
		id: 'fb14',
		category: 'fiberglass',
		src: '/fiberglass/14.png',
	},
	{
		id: 'fb15',
		category: 'fiberglass',
		src: '/fiberglass/15.jpeg',
	},
	{
		id: 'fb16',
		category: 'fiberglass',
		src: '/fiberglass/16.jpeg',
	},
	{
		id: 'fb17',
		category: 'fiberglass',
		src: '/fiberglass/17.jpeg',
	},
	{
		id: 'fb18',
		category: 'fiberglass',
		src: '/fiberglass/18.jpeg',
	},
	{
		id: 'fb19',
		category: 'fiberglass',
		src: '/fiberglass/19.jpeg',
	},
	{
		id: 'fb20',
		category: 'fiberglass',
		src: '/fiberglass/20.jpeg',
	},
	{
		id: 'fb21',
		category: 'fiberglass',
		src: '/fiberglass/21.jpeg',
	},
	{
		id: 'fb23',
		category: 'fiberglass',
		src: '/fiberglass/23.jpeg',
	},
	{
		id: 'fb24',
		category: 'fiberglass',
		src: '/fiberglass/24.jpeg',
	},
	{
		id: 'fb25',
		category: 'fiberglass',
		src: '/fiberglass/25.jpeg',
	},
	{
		id: 'fb26',
		category: 'fiberglass',
		src: '/fiberglass/26.jpeg',
	},
	{
		id: 'fb27',
		category: 'fiberglass',
		src: '/fiberglass/27.jpeg',
	},
	{
		id: 'fb28',
		category: 'fiberglass',
		src: '/fiberglass/28.jpeg',
	},
	{
		id: 'fb29',
		category: 'fiberglass',
		src: '/fiberglass/29.jpeg',
	},
	{
		id: 'fb30',
		category: 'fiberglass',
		src: '/fiberglass/30.png',
	},
	{
		id: 'fb31',
		category: 'fiberglass',
		src: '/fiberglass/31.jpeg',
	},
	{
		id: 'fb32',
		category: 'fiberglass',
		src: '/fiberglass/32.jpeg',
	},
	{
		id: 'fb33',
		category: 'fiberglass',
		src: '/fiberglass/33.jpeg',
	},
	{
		id: 'fb34',
		category: 'fiberglass',
		src: '/fiberglass/34.jpeg',
	},
	{
		id: 'fb35',
		category: 'fiberglass',
		src: '/fiberglass/35.jpeg',
	},
	{
		id: 'fb36',
		category: 'fiberglass',
		src: '/fiberglass/36.jpeg',
	},
	{
		id: 'fb37',
		category: 'fiberglass',
		src: '/fiberglass/37.jpeg',
	},
	{
		id: 'fb38',
		category: 'fiberglass',
		src: '/fiberglass/38.jpeg',
	},
	{
		id: 'fb39',
		category: 'fiberglass',
		src: '/fiberglass/39.jpeg',
	},
	{
		id: 'fb40',
		category: 'fiberglass',
		src: '/fiberglass/40.jpeg',
	},
	{
		id: 'fb41',
		category: 'fiberglass',
		src: '/fiberglass/41.png',
	},
	{
		id: 'fb42',
		category: 'fiberglass',
		src: '/fiberglass/42.png',
	},
	{
		id: 'fb43',
		category: 'fiberglass',
		src: '/fiberglass/43.jpeg',
	},
	{
		id: 'fb44',
		category: 'fiberglass',
		src: '/fiberglass/44.png',
	},
	{
		id: 'fb45',
		category: 'fiberglass',
		src: '/fiberglass/45.jpeg',
	},
	// {
	//     id: "fb46",
	//     category: "fiberglass",
	//     src: "/fiberglass/46.png"
	// },
	// {
	//     id: "fb47",
	//     category: "fiberglass",
	//     src: "/fiberglass/47.jpeg"
	// },
	{
		id: 'fb48',
		category: 'fiberglass',
		src: '/fiberglass/48.jpeg',
	},
	{
		id: 'fb49',
		category: 'fiberglass',
		src: '/fiberglass/49.jpeg',
	},
	{
		id: 'fb50',
		category: 'fiberglass',
		src: '/fiberglass/50.jpeg',
	},
	{
		id: 'fb51',
		category: 'fiberglass',
		src: '/fiberglass/51.jpeg',
	},
	{
		id: 'fb52',
		category: 'fiberglass',
		src: '/fiberglass/52.png',
	},
	{
		id: 'fb53',
		category: 'fiberglass',
		src: '/fiberglass/53.jpeg',
	},
	{
		id: 'fb54',
		category: 'fiberglass',
		src: '/fiberglass/54.jpeg',
	},
	{
		id: 'fb55',
		category: 'fiberglass',
		src: '/fiberglass/55.jpeg',
	},
	{
		id: 'fb56',
		category: 'fiberglass',
		src: '/fiberglass/56.jpeg',
	},
	{
		id: 'fb57',
		category: 'fiberglass',
		src: '/fiberglass/57.jpeg',
	},
	{
		id: 'fb58',
		category: 'fiberglass',
		src: '/fiberglass/58.jpeg',
	},
	{
		id: 'fb59',
		category: 'fiberglass',
		src: '/fiberglass/59.jpeg',
	},
	{
		id: 'fb60',
		category: 'fiberglass',
		src: '/fiberglass/60.jpeg',
	},
	{
		id: 'fb61',
		category: 'fiberglass',
		src: '/fiberglass/61.jpeg',
	},
	{
		id: 'fb62',
		category: 'fiberglass',
		src: '/fiberglass/62.jpeg',
	},
	{
		id: 'fb63',
		category: 'fiberglass',
		src: '/fiberglass/63.jpeg',
	},
	{
		id: 'fb64',
		category: 'fiberglass',
		src: '/fiberglass/64.png',
	},
	{
		id: 'st1',
		category: 'steel',
		src: '/steel/1+.png',
	},
	{
		id: 'st2',
		category: 'steel',
		src: '/steel/2+.png',
	},
	{
		id: 'st3',
		category: 'steel',
		src: '/steel/3.jpeg',
	},
	{
		id: 'st4',
		category: 'steel',
		src: '/steel/4+.png',
	},
	{
		id: 'st5',
		category: 'steel',
		src: '/steel/5.jpeg',
	},
	{
		id: 'st6',
		category: 'steel',
		src: '/steel/6+.png',
	},
	{
		id: 'st7',
		category: 'steel',
		src: '/steel/7.jpeg',
	},
	{
		id: 'st9',
		category: 'steel',
		src: '/steel/9+.png',
	},
	{
		id: 'st10',
		category: 'steel',
		src: '/steel/10.jpeg',
	},
	{
		id: 'st11',
		category: 'steel',
		src: '/steel/11+.png',
	},
	{
		id: 'st12',
		category: 'steel',
		src: '/steel/12.jpeg',
	},
	{
		id: 'st13',
		category: 'steel',
		src: '/steel/13.jpeg',
	},
	{
		id: 'st14',
		category: 'steel',
		src: '/steel/14+.png',
	},
	{
		id: 'st15',
		category: 'steel',
		src: '/steel/15.jpeg',
	},
	{
		id: 'st16',
		category: 'steel',
		src: '/steel/16+.png',
	},
	{
		id: 'st17',
		category: 'steel',
		src: '/steel/17.jpeg',
	},
	{
		id: 'st18',
		category: 'steel',
		src: '/steel/18.jpeg',
	},
	{
		id: 'st19',
		category: 'steel',
		src: '/steel/19.png',
	},
	{
		id: 'st20',
		category: 'steel',
		src: '/steel/20.jpeg',
	},
	{
		id: 'st21',
		category: 'steel',
		src: '/steel/21.jpeg',
	},
	{
		id: 'st22',
		category: 'steel',
		src: '/steel/22+.png',
	},
	{
		id: 'st23',
		category: 'steel',
		src: '/steel/23.jpeg',
	},
	{
		id: 'st24',
		category: 'steel',
		src: '/steel/24+.png',
	},
	{
		id: 'st25',
		category: 'steel',
		src: '/steel/25.jpeg',
	},
	{
		id: 'st26',
		category: 'steel',
		src: '/steel/26+.png',
	},
	{
		id: 'st27',
		category: 'steel',
		src: '/steel/27+.png',
	},
	{
		id: 'st28',
		category: 'steel',
		src: '/steel/28.jpeg',
	},
	{
		id: 'st29',
		category: 'steel',
		src: '/steel/29+.png',
	},
	{
		id: 'st30',
		category: 'steel',
		src: '/steel/30+.png',
	},
	{
		id: 'st31',
		category: 'steel',
		src: '/steel/31.jpeg',
	},
	{
		id: 'st32',
		category: 'steel',
		src: '/steel/32+.png',
	},
	{
		id: 'st33',
		category: 'steel',
		src: '/steel/33+.png',
	},
	{
		id: 'st34',
		category: 'steel',
		src: '/steel/34.jpeg',
	},
	{
		id: 'st35',
		category: 'steel',
		src: '/steel/35.jpeg',
	},
	{
		id: 'st36',
		category: 'steel',
		src: '/steel/36+.png',
	},
	{
		id: 'st37',
		category: 'steel',
		src: '/steel/37.jpeg',
	},
	{
		id: 'st38',
		category: 'steel',
		src: '/steel/38.jpeg',
	},
	{
		id: 'st39',
		category: 'steel',
		src: '/steel/39.png',
	},
	{
		id: 'st40',
		category: 'steel',
		src: '/steel/41.jpeg',
	},
	{
		id: 'st42',
		category: 'steel',
		src: '/steel/42.jpeg',
	},
	{
		id: 'st43',
		category: 'steel',
		src: '/steel/43.jpeg',
	},
	{
		id: 'st44',
		category: 'steel',
		src: '/steel/44+.png',
	},
	{
		id: 'st45',
		category: 'steel',
		src: '/steel/45.jpeg',
	},
	{
		id: 'st46+',
		category: 'steel',
		src: '/steel/46+.jpeg',
	},
	{
		id: 'st47',
		category: 'steel',
		src: '/steel/47+.png',
	},
	{
		id: 'st48',
		category: 'steel',
		src: '/steel/48.jpeg',
	},
]

export const ColorMapping = [
	{
		id: 'CQ-001C',
		previewSrc: '/colors/CQ-001C.jpg',
		src: '/colors/CQ-001C_full.jpg',
	},
	{
		id: 'CQ-005-C',
		previewSrc: '/colors/CQ-005-C.jpg',
		src: '/colors/CQ-005-C_full.jpg',
	},
	{
		id: 'CQ-05K-K',
		previewSrc: '/colors/CQ-05K-K.jpg',
		src: '/colors/CQ-05K-K_full.jpg',
	},
	{
		id: 'CQ-5P3-G',
		previewSrc: '/colors/CQ-5P3-G.jpg',
		src: '/colors/CQ-5P3-G_full.jpg',
	},
	{
		id: 'CQ-5P6-G',
		previewSrc: '/colors/CQ-5P6-G.jpg',
		src: '/colors/CQ-5P6-G_full.jpg',
	},
	{
		id: 'CQ-08K',
		previewSrc: '/colors/CQ-08K.jpg',
		src: '/colors/CQ-08K_full.jpg',
	},
	{
		id: 'CQ-37K-K',
		previewSrc: '/colors/CQ-37K-K.jpg',
		src: '/colors/CQ-37K-K_full.jpg',
	},
	{
		id: 'CQ-095-C',
		previewSrc: '/colors/CQ-095-C.jpg',
		src: '/colors/CQ-095-C_full.jpg',
	},
	{
		id: 'CQ-103-C',
		previewSrc: '/colors/CQ-103-C.jpg',
		src: '/colors/CQ-103-C_full.jpg',
	},
	{
		id: 'CQ-111-C',
		previewSrc: '/colors/CQ-111-C.jpg',
		src: '/colors/CQ-111-C_full.jpg',
	},
	{
		id: 'CQ-112-C',
		previewSrc: '/colors/CQ-112-C.jpg',
		src: '/colors/CQ-112-C_full.jpg',
	},
	{
		id: 'CQ-113-C',
		previewSrc: '/colors/CQ-113-C.jpg',
		src: '/colors/CQ-113-C_full.jpg',
	},
	{
		id: 'CQ-265-G',
		previewSrc: '/colors/CQ-265-G.jpg',
		src: '/colors/CQ-265-G_full.jpg',
	},
	{
		id: 'CQ-506-G',
		previewSrc: '/colors/CQ-5P3-G.jpg',
		src: '/colors/CQ-506-G_full.jpg',
	},
	{
		id: 'CQ-509-G',
		previewSrc: '/colors/CQ-509-G.jpg',
		src: '/colors/CQ-509-G_full.jpg',
	},
	{
		id: 'CQ-510-G',
		previewSrc: '/colors/CQ-510-G.jpg',
		src: '/colors/CQ-510-G_full.jpg',
	},
	{
		id: 'CQ-523-G',
		previewSrc: '/colors/CQ-523-G.jpg',
		src: '/colors/CQ-523-G_full.jpg',
	},
	{
		id: 'CQ-525-G',
		previewSrc: '/colors/CQ-525-G.jpg',
		src: '/colors/CQ-525-G_full.jpg',
	},
	{
		id: 'CQ-536-G',
		previewSrc: '/colors/CQ-536-G.jpg',
		src: '/colors/CQ-536-G_full.jpg',
	},
	{
		id: 'CQ-540-C',
		previewSrc: '/colors/CQ-540-C.jpg',
		src: '/colors/CQ-540-C_full.jpg',
	},
	{
		id: 'CQ-542-G',
		previewSrc: '/colors/CQ-542-G.jpg',
		src: '/colors/CQ-542-G_full.jpg',
	},
	{
		id: 'CQ-559-G',
		previewSrc: '/colors/CQ-559-G.jpg',
		src: '/colors/CQ-559-G_full.jpg',
	},
	{
		id: 'CQ-562-G',
		previewSrc: '/colors/CQ-562-G.jpg',
		src: '/colors/CQ-562-G_full.jpg',
	},
	{
		id: 'CQ-568-G',
		previewSrc: '/colors/CQ-568-G.jpg',
		src: '/colors/CQ-568-G_full.jpg',
	},
]

export const GlassMapping = [
	{
		id: '1',
		src: '/glass/1.jpg',
	},
	{
		id: '3',
		src: '/glass/3.jpg',
	},
	{
		id: '4',
		src: '/glass/4.jpg',
	},
	{
		id: '6',
		src: '/glass/6.jpg',
	},
	{
		id: '7',
		src: '/glass/7.jpg',
	},
	{
		id: '8',
		src: '/glass/8.jpg',
	},
	{
		id: '10',
		src: '/glass/10.jpg',
	},
	{
		id: '11',
		src: '/glass/11.jpg',
	},
	{
		id: '13',
		src: '/glass/13.jpg',
	},
	{
		id: '14',
		src: '/glass/14.jpg',
	},
	{
		id: '15',
		src: '/glass/15.jpg',
	},
	{
		id: '16',
		src: '/glass/16.jpg',
	},
	{
		id: '17',
		src: '/glass/17.jpg',
	},
	{
		id: '18',
		src: '/glass/18.jpg',
	},
	{
		id: '19',
		src: '/glass/19.jpg',
	},
	{
		id: '21',
		src: '/glass/21.jpg',
	},
	{
		id: '25',
		src: '/glass/25.jpg',
	},
	{
		id: '26',
		src: '/glass/26.jpg',
	},
	{
		id: '28',
		src: '/glass/28.jpg',
	},
]

export const sideDoors = [
	{
		id: '1',
		src: '/sideDoors/side_1.svg',
	},
	{
		id: '2',
		src: '/sideDoors/side_2.svg',
	},
	{
		id: '3',
		src: '/sideDoors/side_3.svg',
	},
	{
		id: '4',
		src: '/sideDoors/side_4.svg',
	},
	{
		id: '5',
		src: '/sideDoors/side_5.svg',
	},
	{
		id: '6',
		src: '/sideDoors/side_6.svg',
	},
	{
		id: '7',
		src: '/sideDoors/side_7.svg',
	},
	{
		id: '8',
		src: '/sideDoors/side_8.svg',
	},
	{
		id: '9',
		src: '/sideDoors/side_9.svg',
	},
	{
		id: '10',
		src: '/sideDoors/side_10.svg',
	},
	{
		id: '11',
		src: '/sideDoors/side_11.svg',
	},
	{
		id: '12',
		src: '/sideDoors/side_12.svg',
	},
	{
		id: '13',
		src: '/sideDoors/side_13.svg',
	},
	{
		id: '14',
		src: '/sideDoors/side_14.svg',
	},
	{
		id: '15',
		src: '/sideDoors/side_15.svg',
	},
	{
		id: '16',
		src: '/sideDoors/side_16.svg',
	},
	{
		id: '17',
		src: '/sideDoors/side_17.svg',
	},
	{
		id: '18',
		src: '/sideDoors/side_18.svg',
	},
	{
		id: '19',
		src: '/sideDoors/side_19.svg',
	},
	{
		id: '20',
		src: '/sideDoors/side_20.svg',
	},
	{
		id: '21',
		src: '/sideDoors/side_21.svg',
	},
	{
		id: '22',
		src: '/sideDoors/side_22.svg',
	},
	{
		id: '23',
		src: '/sideDoors/side_23.svg',
	},
	{
		id: '24',
		src: '/sideDoors/side_24.svg',
	},
	{
		id: '25',
		src: '/sideDoors/side_25.svg',
	},
	{
		id: '26',
		src: '/sideDoors/side_26.svg',
	},
	{
		id: '27',
		src: '/sideDoors/side_27.svg',
	},
	{
		id: '28',
		src: '/sideDoors/side_28.svg',
	},
	{
		id: '29',
		src: '/sideDoors/side_29.svg',
	},
	{
		id: '30',
		src: '/sideDoors/side_30.svg',
	},
	{
		id: '31',
		src: '/sideDoors/side_31.svg',
	},
	// {
	// 	id: '32',
	// 	src: '/sideDoors/side_32.svg',
	// },
	// {
	// 	id: '33',
	// 	src: '/sideDoors/side_33.svg',
	// },
	// {
	// 	id: '34',
	// 	src: '/sideDoors/side_34.svg',
	// },
	// {
	// 	id: '35',
	// 	src: '/sideDoors/side_35.svg',
	// },
	// {
	// 	id: '36',
	// 	src: '/sideDoors/side_36.svg',
	// },
	// {
	// 	id: '37',
	// 	src: '/sideDoors/side_37.svg',
	// },
	// {
	// 	id: '38',
	// 	src: '/sideDoors/side_38.svg',
	// },
	// {
	// 	id: '39',
	// 	src: '/sideDoors/side_39.svg',
	// },
	// {
	// 	id: '40',
	// 	src: '/sideDoors/side_40.svg',
	// },
	// {
	// 	id: '41',
	// 	src: '/sideDoors/side_41.svg',
	// },
	// {
	// 	id: '42',
	// 	src: '/sideDoors/side_42.svg',
	// },
	// {
	// 	id: '43',
	// 	src: '/sideDoors/side_43.svg',
	// },
	// {
	// 	id: '44',
	// 	src: '/sideDoors/side_44.svg',
	// },
	// {
	// 	id: '45',
	// 	src: '/sideDoors/side_45.svg',
	// },
	// {
	// 	id: '46',
	// 	src: '/sideDoors/side_46.svg',
	// },
	// {
	// 	id: '47',
	// 	src: '/sideDoors/side_47.svg',
	// },
	// {
	// 	id: '48',
	// 	src: '/sideDoors/side_48.svg',
	// },
	// {
	// 	id: '49',
	// 	src: '/sideDoors/side_49.svg',
	// },
	// {
	// 	id: '50',
	// 	src: '/sideDoors/side_50.svg',
	// },
	// {
	// 	id: '51',
	// 	src: '/sideDoors/side_51.svg',
	// },
	// {
	// 	id: '52',
	// 	src: '/sideDoors/side_52.svg',
	// },
	// {
	// 	id: '53',
	// 	src: '/sideDoors/side_53.svg',
	// },
	// {
	// 	id: '54',
	// 	src: '/sideDoors/side_54.svg',
	// },
	// {
	// 	id: '55',
	// 	src: '/sideDoors/side_55.svg',
	// },
	// {
	// 	id: '56',
	// 	src: '/sideDoors/side_56.svg',
	// },
	// {
	// 	id: '57',
	// 	src: '/sideDoors/side_57.svg',
	// },
	// {
	// 	id: '58',
	// 	src: '/sideDoors/side_58.svg',
	// },
	// {
	// 	id: '59',
	// 	src: '/sideDoors/side_59.svg',
	// },
	// {
	// 	id: '60',
	// 	src: '/sideDoors/side_60.svg',
	// },
	// {
	// 	id: '61',
	// 	src: '/sideDoors/side_61.svg',
	// },
	// {
	// 	id: '62',
	// 	src: '/sideDoors/side_62.svg',
	// },
	// {
	// 	id: '63',
	// 	src: '/sideDoors/side_63.svg',
	// },
]
